import { createGlobalStyle } from "styled-components";
import breakpoints from "./breakpoints";
import '@fontsource-variable/dm-sans';

function LightenDarkenColor(col, amt) {
  
  let usePound = false;

  if (col[0] == "#") {
      col = col.slice(1);
      usePound = true;
  }

  const num = parseInt(col,16);

  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if  (r < 0) r = 0;

  let b = ((num >> 8) & 0x00FF) + amt;

  if (b > 255) b = 255;
  else if  (b < 0) b = 0;

  let g = (num & 0x0000FF) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);

}

const lightenSecondary = props => {
  return LightenDarkenColor(props.secondaryColor.hex, 20); 
};

const lightenPrimary = props => {
  return LightenDarkenColor(props.primaryColor.hex, 20); 
};


const GlobalStyles = createGlobalStyle`

:root {
    --primary: ${props => props.primaryColor.hex};
    --secondary: ${props => props.secondaryColor.hex};
    --lightenButton: ${lightenSecondary};
    --lightenPrimary: ${lightenPrimary};
    --white: #fff;
    --black: #050505;
    --grey: #efefef;
    --mediumkgrey: #A2A2A2;
    --drkGrey: #989898;
    --silverGrey: #cccccc;
    --lightGrey: #F5F5F5; 
    --codGrey: #191919;
    --blue: #2300FF;
    --cornflowerBlue: #4D31FD;
    --darkGrey: #4A4A4A;
    --successGreen: #4BB543;
}

  html, body {
    scroll-behavior: smooth;
  }

  html {
    background: var(--grey);
    font-size: 16px;
  }

  body {
    font-size: 1rem;
    font-family: "DM Sans Variable", sans-serif;
    font-display: optional;
  }
  
  p {
    font-size: 1rem;
    line-height: 1.4;
    margin: 0 0 0.9375rem 0;
    overflow-wrap: break-word;
  }
  
  h2 {
    margin: 0 0 1.125rem;
    font-size: 1.5rem;
    line-height: 1.5;
  }
  
  h3 {
    font-size: 1.25rem;
    line-height: 1.5;

    @media only screen and (min-width: ${breakpoints.lg}){
      margin: 1.125rem 0;
    }
  }

  h4 {
    font-size: 1rem;
    line-height: 1.4;

  }
  
  ol {
    margin: 0.9375rem;
  }
  
  li {
    line-height: 1.3;
    margin-bottom: 0.3125rem;
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  img {
    max-width: 100%;
  }

  /* container styles*/

  .container {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
  }

  .pad {
    padding: 0 1rem;
  }

  /* desktop */ 

  @media only screen and (min-width: 1024px) {
    .container {
        max-width: 80rem; /*1280px*/
    }
  }
  `;

  export default GlobalStyles;